import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@mui/material/Paper";
import {Button, CircularProgress, Grid} from "@mui/material";
import '../../../node_modules/react-vis/dist/style.css';
import {
  Hint,
  HorizontalGridLines,
  VerticalBarSeries,
  XAxis,
  XYPlot,
  YAxis
} from "react-vis";
import {getGraphDataRequest, getGraphExcelRequest} from "../../redux/reducers/archiveReducer/actions";
import ExcelIcon from './excel_logo.png';
import IconButton from "@mui/material/IconButton";
import {StyledOEE} from "./StyledOEE";
import {sendGeneralMessage} from "../../redux/reducers/layoutReducer/actions";

export default function OEE() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {filter_start, filter_end, first_load, maxValue, graphDataLoading, weights, total_weight,
    graphData, graphExcelLoading} = useSelector((state) => state.archiveReducer)
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [operator, setOperator] = useState('all')
  const {operators} = useSelector((state) => state.userReducer)

  useEffect(() => {
    if(!first_load){
      setFromDate(filter_start)
      setToDate(filter_end)
      return
    }
    let firstDay = new Date();
    let lastDay = new Date();
    firstDay.setFullYear(firstDay.getFullYear(), firstDay.getMonth(), 1);
    lastDay.setFullYear(lastDay.getFullYear(), lastDay.getMonth() + 1, 0);
    setFromDate(firstDay.toISOString().substring(0, 10))
    setToDate(lastDay.toISOString().substring(0, 10))
    dispatch(getGraphDataRequest(t, {'start': firstDay.toISOString().substring(0, 10),
      'end': lastDay.toISOString().substring(0, 10)}, operator))
  }, [])

  const renew_graph = () => {
    const date1 = new Date(fromDate);
    const date2 = new Date(toDate);
    if(isNaN(date1) || isNaN(date2)){
      dispatch(sendGeneralMessage('Enter valid dates!', 'error'))
      return
    }
    if(date1 > date2){
      dispatch(sendGeneralMessage('Date from should be before to Date', 'error'))
      return
    }
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if(diffDays > 10950){
      alert(t('alert_max_30_years'))
      return
    }
    dispatch(getGraphDataRequest(t, {'start': fromDate, 'end': toDate}, operator))
  }

  return(
    <StyledOEE>
      <Paper style={{backgroundColor: '#141414', borderRadius: '10px', position: 'relative',
        height: '85px', display: 'flex', width: '100%', color: 'white'}}>
        <Grid container>
          <Grid item xs={5} sm={5} style={{padding: '10px'}}>
            <div>
              <span>Operator:</span>
              <select value={operator} onChange={(e) => {
                setOperator(e.target.value)}} style={{margin: '0px 10px'}}>
                <option key={"all"} value="all">All</option>
                {operators && operators.map((item) => {
                  return <option key={item.username} value={item.username}>{item.username}</option>
                })}
              </select>
            </div>
            <div className={"filters"}>{t('From_date')}:
              <input type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)}
                     style={{margin: '0px 10px'}}/>
              {t('To_date')}:
              <input type="date" value={toDate} onChange={(e) => setToDate(e.target.value)}
                     style={{margin: '0px 10px'}}/>
              {graphDataLoading ?
                <CircularProgress style={{color: 'white', marginLeft: '20px'}} size={34}/>
                                :
                  <Button variant="contained" color="secondary" component="label" size={"small"}
                         onClick={() => renew_graph()} style={{marginLeft: '10px'}}>
                  {t('show')}
                </Button>
              }
              {graphExcelLoading ?
                 <CircularProgress style={{color: 'white', marginLeft: '20px'}} size={34}/>
                                 :
                 <IconButton style={{marginLeft: '10px'}} onClick={() => {
                   dispatch(getGraphExcelRequest({'start': fromDate, 'end': toDate}, operator))
                 }}>
                   <img alt="" src={ExcelIcon} style={{height: '40px'}}/>
                 </IconButton>
              }
            </div>
          </Grid>
          <Grid item xs={7} sm={7} style={{height: '100%', width: '100%'}}>
            <div className={"total_box"}>
              <div className={"total_box_title"}>{t('Total_assembled')}</div>
              <div className={"total_box_value"}>{maxValue.total_min}</div>
            </div>
            <div className={"total_box"}>
              <div className={"total_box_title"}>{t('Total_weight')}</div>
              <div className={"total_box_value"}>{total_weight.toFixed(2)} {t('kg')}</div>
            </div>
            <div className={"total_box"}>
              <div className={"total_box_title"}>{t('total_parts')}</div>
              <div className={"total_box_value"}>{graphData.total_parts}</div>
            </div>
            <div className={"total_box_last"}>
              <div className={"total_box_title"}>{t('avg_min_part')}</div>
              <div className={"total_box_value"}>{graphData.avg_time}</div>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{backgroundColor: 'white', borderRadius: '10px', position: 'relative',
        height: 'calc(100% - 85px)', display: 'flex', width: '100%'}}>
          {graphDataLoading ?
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
              <CircularProgress color="primary" style={{padding: '20px'}}/>
            </div>
            :  <Grid item xs={12} sm={12} style={{textAlign: 'center', overflow: 'scroll', color: 'black'}}>
              <div className={"graph_title"}>{t('graph1_title')}</div>
              <XYPlot height={300} width={1600} yDomain={[0, maxValue.max_graph_1]} xType='ordinal'>
                <HorizontalGridLines/>
                <XAxis className={"x_axis"}/>
                <YAxis className={"y_axis"}/>
                <VerticalBarSeries data={graphData.graph_1}/>
                {graphData.graph_1 && graphData.graph_1.map((item, index) => {
                  return <Hint value={item} className={item['y'] !== 0 ? "hint_graph1": "hint_graph1_0"} align={{vertical: 'top', horizontal: 'left'}} key={index}>
                      {weights[index] !== 0 && <div className={"hint_trans"} style={index === 0 ? {marginRight: '-12px'} : {}}> {weights[index].toFixed(2)} {t('kg')}</div>}
                      <h3 style={{marginTop: '0'}}>{item['y']}</h3>
                  </Hint>
                })}
              </XYPlot>
              <div className={"graph_title"}>{t('graph2_title')}</div>
              <XYPlot height={300} width={1600} yDomain={[0, maxValue.max_graph_2]} xType='ordinal'>
                <HorizontalGridLines/>
                <XAxis className={"x_axis"}/>
                <YAxis className={"y_axis"}/>
                <VerticalBarSeries data={graphData.graph_2} />
                {graphData.graph_2 && graphData.graph_2.map((item, index) => {
                  return <Hint value={item} style={{fontSize: 14, marginLeft: '-10px'}} align={{vertical: 'top', horizontal: 'right'}} key={index}>
                    <div style={{padding: '0px 2px', color: 'black'}}>
                      <h3>{item['y']}</h3>
                    </div>
                  </Hint>
                })}
              </XYPlot>
              <div className={"graph_title"}>{t('graph3_title')}</div>
              <XYPlot height={300} width={1600} yDomain={[0, maxValue.max_graph_3]} xType='ordinal'>
                <HorizontalGridLines/>
                <XAxis className={"x_axis"}/>
                <YAxis className={"y_axis"}/>
                <VerticalBarSeries data={graphData.graph_3} />
                {graphData.graph_3 && graphData.graph_3.map((item, index) => {
                  return <Hint value={item} style={{fontSize: 14, marginLeft: '-14px'}} align={{vertical: 'top', horizontal: 'right'}} key={index}>
                    <div style={{padding: '0px 2px', color: 'black'}}>
                      <h3>{item['y']}%</h3>
                    </div>
                  </Hint>
                })}
              </XYPlot>
            </Grid>}
      </Paper>
    </StyledOEE>
  )
}
